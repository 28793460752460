export enum AdminRoute {
    Landing = '',
    Admin = 'admin',
    Events = 'events',
    Videos = 'videos',
    Authors = 'authors',
    Organizations = 'organizations',
    Projects = 'projects',
    Categories = 'categories',
    Playlists = 'playlists',
    Users = 'users',
    Incoming = 'incoming',
}
